import { RouteRecordRaw } from 'vue-router';
import AppConfig from './appConfig.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/appConfig',
        component: AppConfig,
        meta: { title: "App Config"},
    }
];

export default routes;