
import { Prop, Watch } from 'vue-property-decorator';
import Chart from 'chart.js'
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class DoughnutChart extends Vue {
    
    @Prop() value: number[];
    @Prop() label: string[];
    @Prop({
        default: '#7FD6DC'
    }) color: string[];
    @Prop() maxy: number;
    @Prop() centerLabel: string;
    @Prop() centerNumber: string;

    showFakeDoughnut: boolean = false;

    chart: any;
    mounted(){
        this.chartIt();
    }

    @Watch('value')
    onDataChange(next, prev){
        this.chart.destroy();
        this.chartIt();
    }

    chartIt(){
        this.showFakeDoughnut = this.value.every(x => x == 0);

        let ctx: any = (this.$refs.chart as any).getContext('2d');
        this.chart = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: this.label,
                datasets: [{
                    data: this.value,
                    backgroundColor: this.color,
                }]
            },
            options: {
                layout: {
                    padding: 0
                },
                aspectRatio: 1.45,
                cutoutPercentage: 70, 
                legend: {
                    display: false
                },
                showAllTooltips: true,
                animation: false,
                tooltips: {
                    backgroundColor: '#fff',
                    bodyFontColor: '#000',
                    displayColors: false,
                }
            }
        });
    }

}

