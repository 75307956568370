export function EnumToList(inputEnum: any): { [key: string]: number | string } {
    let ris: any = {};
    for(var prop in inputEnum){
        if (typeof inputEnum[prop] === 'number') {
            ris[inputEnum[prop]] = prop.replace(/_/g, ' ');
        }
    }
    return ris;
}
class Enum {
    static GetAll(){
        let ris = [];
        for(let p in this){
            ris.push(this[p]);
        }
        return ris;
    }
    static GetDictionary(){
        let ris = {};
        for(let p in this){
            ris[p] = this[p];
        }
        return ris;
    }
}

export class DayOfWeek extends Enum
{
    static Sunday: string = "0";
    static Monday: string = "1";
    static Tuesday: string = "2";
    static Wednesday: string = "3";
    static Thursday: string = "4";
    static Friday: string = "5";
    static Saturday: string = "6";
}


export class RoutesGroup extends Enum {
    static Performance: RoutesGroupConfig = { text: "Performance", order: 1 };
    static Gestione: RoutesGroupConfig = { text: "Gestione", order: 2 };
    static Marketing: RoutesGroupConfig = { text: "Marketing", order: 3 };
    static Configurazione: RoutesGroupConfig = { text: "Configurazione", order: 4 };
}
class RoutesGroupConfig {
    text: string;
    order: number;
}

export class UserRoles extends Enum
{
    static Cartella: string = "Cartella";
    static Lead: string = "Lead";
    static Cliente: string = "Cliente";
    static Collaboratore: string = "Collaboratore";
    static CentroFumo: string = "CentroFumo";
    static Dottore: string = "Dottore";
    static Manager: string = "Manager";
    static Admin: string = "Admin";
}

export class DatabaseNames extends Enum
{
    static Mosaico: string = "Mosaico";
    static Dimagriamo: string = "Dimagriamo";
    static AntiSmoking: string = "AntiSmoking";
    static Nutrilab: string = "Nutrilab";
    static NutriAmo: string = "Nutriamo";
    static FranchisingClinicheItalia: string = "Franchising Cliniche Italia";
    static SwissHealthClinicsTicino: string = "Swiss Health Clinics Ticino";
    static NutrilabEsthetics: string = "Nutrilab Esthetics";
    static NutrizionistiCheGuadagnano: string = "Nutrizionisti che guadagnano";
    static NutriEasy: string = "NutriEasy";
}