import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "mt-5 mw-700" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h4", { innerHTML: _ctx.title }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event))
        }, null, 8, _hoisted_8), [
          [_vModelText, _ctx.input]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-block btn-primary",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ok && _ctx.ok(...args)))
        }, [
          _createElementVNode("span", { innerHTML: _ctx.okText }, null, 8, _hoisted_10)
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-block btn-hollow mt-2",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.ko && _ctx.ko(...args)))
        }, [
          _createElementVNode("span", { innerHTML: _ctx.koText }, null, 8, _hoisted_11)
        ])
      ])
    ])
  ]))
}