import { RouteRecordRaw } from 'vue-router';
import Dashboard from './dashboard.vue';


let routes: RouteRecordRaw[] = [
    {
        path: '/dashboard',
        component: Dashboard,
        meta: { title: "Dashboard utente", routeBuster: true },
    },
    {
        path: '/dashboard/:userIdentifier',
        component: Dashboard,
        meta: { title: "Dashboard utente", routeBuster: true },
    }
];

export default routes;