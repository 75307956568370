
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient } from '@/services/Services';

@Options({})
export default class authData extends Vue {

    @Prop() userIdentifier: string;
    @Prop() value: OM.AuthData;

    changePassword: boolean = false;
    newPassword: string = "";

    doChangePassword(){
        this.changePassword = !this.changePassword;
    }

    setNewPassword(){
        BaseUserClient.setNewPassword(this.userIdentifier, this.newPassword)
        .then(x => {
            this.value.password = x;
            this.changePassword = false;
        })
    }

}
