import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dashboard"
}
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = { class: "d-flex align-items-center mb-4" }
const _hoisted_4 = { class: "dashboardName ml-2" }
const _hoisted_5 = {
  key: 0,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_dashboard_fund = _resolveComponent("dashboard-fund")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.user)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_5, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Ciao 👋",
                      text: "Ciao 👋"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.user.personalData.completeName), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createVNode(_component_dashboard_fund, { user: _ctx.user }, null, 8, ["user"])
      ]))
    : _createCommentVNode("", true)
}