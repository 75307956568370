

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import toastr from 'toastr'
import { AppConfigClient } from '@/services/Services';
import roleModules from './roleModules.vue';

@Options({
    components: {
        roleModules,
    }
})
export default class AppConfig extends Vue {

    config: OM.AppConfig = null;
    days: any = Enum.DayOfWeek.GetAll();

    created(){
        this.init();
    }

    init(){
        this.config = null;
        AppConfigClient.getConfig()
        .then( x => {
            this.config = x;
        });
    }

    save(){
        AppConfigClient.save(this.config)
        .then(x => {
            toastr.success("Salvato!");
            this.init();
        })
        .catch( err => {
            toastr.error("Errore");  
        });
    }

}

