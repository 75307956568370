
import WithModel from "@/mixins/withModel";
import * as OM from '@/Model';
import moment from "moment";
import { mixins, Options } from "vue-class-component";

@Options({})
export default class monthNavigator extends mixins(WithModel) {

    declare localValue: string;

    prevMonth(){
        this.localValue = moment(this.localValue).subtract(1, 'month').startOf('month').toISOString();
        this.emitModel(this.localValue);
    }

    nextMonth(){
        this.localValue = moment(this.localValue).add(1, 'month').startOf('month').toISOString();
        this.emitModel(this.localValue);
    }

};
