
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class BarreRisultato extends Vue {

    @Prop() risultato: number;
    @Prop() obiettivo: number;
    
    targetWidth: string = "width: 0%;";
    resultWidth: string = "width: 0%;";
    risultatoPerc: number = 0;
    isOver: boolean = false;

    created(){
        this.calc();
    }

    @Watch('risultato')
    @Watch('obiettivo')
    onChange(next, prev){
        this.calc();
    }

    calc(){
        if(this.obiettivo == 0){
            this.targetWidth = "width: auto;";
            this.resultWidth = "width: auto;";
            this.risultatoPerc = 0;
            if(this.risultato > 0)
                this.isOver = true;
            return;
        }
        setTimeout(() => {
            this.isOver = false;
            this.risultatoPerc = this.risultato * 100 / this.obiettivo;
            if(this.risultatoPerc > 100){
                let targetPerc = this.obiettivo * 100 / this.risultato;
                this.resultWidth = `width: 100%;`;
                this.targetWidth = `width: ${targetPerc}%;`;
                this.isOver = true;
                // this.risultatoPerc = this.risultatoPerc - 100;
            } else {
                this.targetWidth = `width: 100%;`;
                this.resultWidth = `width: ${this.risultatoPerc}%;`;
            }
        }, 50)
    }

}

