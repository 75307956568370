
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class ListFiller extends mixins(WithModel) {

    @Prop() label: string;

    declare modelValue: string[];

    add(){
        this.localValue.push("");
    }
    remove(index){
        this.localValue.splice(index, 1);
    }

}

