

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient } from '@/services/Services';

@Options({})
export default class collaboratori extends Vue {

    items: OM.BaseUser[] = [];
    
    created(){ this.init() }
    init(){
        BaseUserClient.getAll()
        .then( x => {
            this.items = x;
        });
    }

    filtersFn = {
    }

    removeItem(item){
        if(!confirm("Sicuro?"))
            return;

        BaseUserClient.deleteById(item.identifier)
        .then(x => {
            this.init();
            window.toastr.success("Rimosso");
        });
    }

}

