

import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { BaseUserClient, ResumeConfigClient } from '@/services/Services';
import store from '@/store';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class resumeConfigSelector extends Vue {
 
    @Prop() user: OM.BaseUser;

    resumeConfigs: OM.NameIdentifier[] = [];
    tempConfig: OM.NameIdentifier = null;

    created(){
        ResumeConfigClient.getAsNameIdentifier().then(x => this.resumeConfigs = x);
    }

    get availableConfigs(){
        return this.resumeConfigs.filter(x => !this.user.resumeConfigAvailable.some(c => c.name == x.name));
    }

    removeRole(i: number){
        this.user.resumeConfigAvailable.splice(i, 1);
    }

    addConfig(tempConfig: OM.NameIdentifier){
        this.user.resumeConfigAvailable.push(tempConfig);
        this.tempConfig = null;
    }

}

