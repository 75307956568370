import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-4" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  key: 0,
  class: "form-control"
}
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-6 mb-3" }
const _hoisted_8 = { class: "box mb-3" }
const _hoisted_9 = { class: "boxTitle" }
const _hoisted_10 = { class: "col-sm-6 mb-3" }
const _hoisted_11 = { class: "box mb-3" }
const _hoisted_12 = { class: "boxTitle" }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_personal_data = _resolveComponent("personal-data")!
  const _component_accordion = _resolveComponent("accordion")!
  const _component_auth_data = _resolveComponent("auth-data")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Ruolo",
              text: "Ruolo"
            })
          ]),
          (_ctx.readonly)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.user.role), 1))
            : _withDirectives((_openBlock(), _createElementBlock("select", {
                key: 1,
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.role) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rolesList, (item, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: item
                  }, _toDisplayString(item), 9, _hoisted_5))
                }), 128))
              ], 512)), [
                [_vModelSelect, _ctx.user.role]
              ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_accordion, null, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Dati personali",
                  text: "Dati personali"
                })
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_personal_data, {
                value: _ctx.user.personalData,
                readonly: _ctx.readonly
              }, null, 8, ["value", "readonly"])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_accordion, null, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Dati di accesso",
                  text: "Dati di accesso"
                })
              ])
            ]),
            default: _withCtx(() => [
              (_ctx.user.identifier)
                ? (_openBlock(), _createBlock(_component_auth_data, {
                    key: 0,
                    value: _ctx.user.authData,
                    "user-identifier": _ctx.user.identifier
                  }, null, 8, ["value", "user-identifier"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Salva per modificare i dati di logio",
                      text: "Salva per modificare i dati di login"
                    })
                  ]))
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}