import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/spesaProject/list',
        component: () => import("./spesaProjectList.vue"),
        meta: { title: "Spese progetti"},
    },
];

export default routes;