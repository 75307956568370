

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { BaseUserClient, ResumeConfigClient } from '@/services/Services';
import store from '@/store';
import resumeConfigSelector from './resumeConfigSelector.vue'

@Options({
    components: {
        resumeConfigSelector
    }
})
export default class utenzeDetail extends Vue {
 
    user: OM.BaseUser = null;
    rolesList: string[] = [];
    resumeConfigs: OM.NameIdentifier[] = [];

    created(){
        if(this.$route.params.identifier as string == '0'){
            this.user = new OM.BaseUser();
        } else {
            this.init();
        }
    }

    init(){
        BaseUserClient.getById(<string>this.$route.params.identifier)
        .then( x => {
            this.user = x;
        });
    }

    save(){
        BaseUserClient.save(this.user)
        .then(x => {
            window.toastr.success("OK!");
            if(<string>this.$route.params.identifier == '0'){
                this.$router.replace('/utenze/detail/' + x);
            }
            this.init();
        })
    }

}

