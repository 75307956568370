
import { Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';
import EventBus from '@/services/EventBus';
import { BaseUserClient } from '@/services/Services';

@Options({})
export default class Dashboard extends Vue {

    user: OM.BaseUser = null;
    reload: number = 0;
    userIdentifier: string = "";

    created(){
        this.userIdentifier = this.$route.params.userIdentifier as string || store.state.loginData.userIdentifier as string;
        this.init();
        EventBus.$on('reloaddash', () => {
            this.init();
        })
    }
    init(){
        this.loadUser();
        this.reload++;
    }

    loadUser(){
        BaseUserClient.getById(this.userIdentifier)
        .then(x => this.user = x)
    }

}

