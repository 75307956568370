import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';

export class BackendModules
{
    static Dashboard = 1;
    static Utenze = 2;
    static AppConfig = 3;
    static Dictionary = 4;
    static ResumeConfig = 5;
    static SpesaProject = 6;
}

export class AppRoute {
    id: number;
    label: string;
    opened: boolean;
    routeName: string;
    icon: string;
    children: AppRoute[];
    visibleFunction: () => boolean;
    group: Enum.RoutesGroup;
    constructor(id: number, label: string, routeName: string, icon: string, group: Enum.RoutesGroup, children?: AppRoute[], 
        visibleFunction?: () => boolean){
        this.id = id;
        this.opened = false;
        this.label = label;
        this.routeName = routeName;
        this.icon = icon;
        this.children = children || [];
        this.visibleFunction = visibleFunction;
        this.group = group;
    }
}

export class ButtonSettings {
    text: string;
    callback: any;
}

export class LayoutSlug
{
    text: string;
    selected?: boolean;
    callback: (item: LayoutSlug) => void;
    removeCallback?: (item: LayoutSlug) => void;
}

export class UploadFileVm
{
    itemIdentifier: string;
    file: File;
}

export class UploadCommentAttachmentVm extends UploadFileVm
{
    text: string;
}

export class NameCount {
    name: string;
    count: number;
}

export class DashboardDateItems {
    date: Date;
    piani: NameCount[];
    daFare: NameCount[];
    inAttesa: NameCount[];
    constructor(){
        this.piani = [];
        this.daFare = [];
        this.inAttesa = [];
    }
}

export class AddCommentEvent {
    fileList: File[];
    text: string;
}

export class AddCommentVm
{
    allegati: File[];
    text: string;
    entityIdentifier: string;
}
