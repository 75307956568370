
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import WithModel from '@/mixins/withModel';

@Options({})
export default class opAddress extends mixins(WithModel) {
    
    @Prop({
        default: false
    }) readonly: boolean;
    @Prop() label: string;

    // regioni: string[] = Enum.Regioni.GetAll();
    // nazioni: string[] = [];
    googleAddress: any = null;
    declare localValue: any; //OM.Address;

    created(){
        // NazioneClient.getNazioni()
        // .then(x => {
        //     this.nazioni = x;
        // })
        this.googleAddress = this.localValue.typedAddress;
    }

    onModelValueChange(next, prev){
        this.localValue = next;
        this.googleAddress = this.localValue.typedAddress;
    }

    @Watch('googleAddress')
    onGoogleAddressChange(next, prev){
        if(typeof next != 'object')
            return;

        this.localValue.typedAddress = next.formatted_address;
        this.localValue.completeAddress = next.formatted_address;
        this.localValue.latLong.lat = next.geometry.location.lat();
        this.localValue.latLong.long = next.geometry.location.lng();
        //this.localValue.name = next.name;
        this.localValue.civico = this.returnValue(next.address_components, "street_number");
        this.localValue.via = this.returnValue(next.address_components, "route");
        this.localValue.nazione = this.returnValue(next.address_components, "country"); // + " (" + this.returnValue(next.address_components, "country", true) + ")";
        // this.localValue.countryCode = this.returnValue(next.address_components, "country_code");
        this.localValue.citta = this.returnValue(next.address_components, "administrative_area_level_3");
        this.localValue.cap = this.returnValue(next.address_components, "postal_code");
        this.localValue.provincia = this.returnValue(next.address_components, "administrative_area_level_2", true);
        this.localValue.regione = this.returnValue(next.address_components, "administrative_area_level_1");

        this.googleAddress = next.formatted_address;
    }

    returnValue(array: any[], key: string, shortName: boolean = false){
        var object = array.find( x => {
            return x.types.find( y => {
                return y == key
            })
        });
        
        if(object){
            if(!shortName)
                return object.long_name;
            return object.short_name;
        }
        return "";
    }

}

