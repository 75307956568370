
import WithModel from '@/mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Toggler extends mixins(WithModel) {

    @Prop() inverse: boolean;
    
    toggle(){
        this.emitModel(!this.localValue);
    }

}
