import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flexRow"
}
const _hoisted_2 = { class: "text-center mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_role_modules = _resolveComponent("role-modules")!
  const _component_accordion = _resolveComponent("accordion")!
  const _component_list_filler = _resolveComponent("list-filler")!

  return (_ctx.config)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h4", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Configurazione generale",
            text: "Configurazione generale"
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Salva configurazione",
              text: "Salva configurazione"
            })
          ])
        ]),
        _createVNode(_component_accordion, { class: "mb-3" }, {
          title: _withCtx(() => [
            _createElementVNode("h3", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Moduli per ruolo",
                text: "Moduli per ruolo"
              })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_role_modules, {
              modelValue: _ctx.config.roleModules,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.config.roleModules) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_accordion, { class: "mb-3" }, {
          title: _withCtx(() => [
            _createElementVNode("h3", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Categorie di spesa",
                text: "Categorie di spesa"
              })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_list_filler, {
              modelValue: _ctx.config.categoriaSpese,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.config.categoriaSpese) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}