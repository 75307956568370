import utenzeList from './utenzeList.vue';
import utenzeDetail from './utenzeDetail.vue';
import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/utenze/list',
        component: utenzeList,
        meta: { title: "Utenze"},
    },
    {
        path: '/utenze/detail/:identifier',
        component: utenzeDetail,
        meta: { title: "Dettaglio utente"},
    }
];

export default routes;