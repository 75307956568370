import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "list-group mt-2" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createVNode(_component_localized_text, {
        localizedKey: "Aggiungi dashboard company",
        text: "Aggiungi dashboard company"
      })
    ]),
    _withDirectives(_createElementVNode("select", {
      class: "form-control",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tempConfig) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addConfig(_ctx.tempConfig)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableConfigs, (item, i) => {
        return (_openBlock(), _createElementBlock("option", {
          key: i,
          value: item
        }, _toDisplayString(item.name), 9, _hoisted_2))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.tempConfig]
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.resumeConfigAvailable, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "list-group-item d-flex justify-content-between align-items-center",
          key: i
        }, [
          _createElementVNode("div", null, _toDisplayString(item.name), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-danger btn-sm",
            onClick: ($event: any) => (_ctx.removeRole(i))
          }, "-", 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}