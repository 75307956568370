
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import { LoginClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';

@Options({})
export default class LoginView extends Vue {

    vm = { email: '', password: ''};
    showPassword: boolean = false;
    error: string = "";
    login(){
        let loginVm: OM.LoginVm = {
            username: this.vm.email, 
            password: this.vm.password
        }
        LoginClient.login(loginVm)
        .then(x => {
            LoginServices.loginCallback(x);
        }, err => {
            if(err == "password_scaduta"){
                this.$router.push('request-new-password?p_expired=true')
            } else {
                this.error = "Errore in fase di login";
            }
        });
    }
}

