import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dashboard"
}
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_resume = _resolveComponent("company-resume")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resumeConfigs, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "mb-3 col-sm-6",
              key: i
            }, [
              _createVNode(_component_company_resume, {
                "resume-name-id": item,
                user: _ctx.user
              }, null, 8, ["resume-name-id", "user"])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}