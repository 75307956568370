
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class ListSelector extends Vue {
    @Prop({
        type: [Array, Object]
    }) list: any[];
    @Prop() value: any[];

    chekky: any = { checked: false };
    ris: any[] = [];
    created(){
        this.ris = this.value;
    }
    mounted(){
        this.chekky = this.$refs.chekky;
    }
    @Watch('ris.length')
    onChange(){
        this.$emit('input', this.ris)
    }

    selectAll(select: boolean){
        if(select){
            this.ris = [];
            this.ris.push(...this.list.map(x => x.identifier));
        } else {
            this.ris = [];
        }
    }
}

