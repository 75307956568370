
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';
import { SpesaProjectClient } from '@/services/Services';
import moment from 'moment';

@Options({})
export default class speseOfMonthModal extends Vue {

    @Prop() fromDate: string;
    @Prop() databaseName: string;

    items: OM.SpesaProject[] = [];

    created(){
        SpesaProjectClient.getByPeriod(this.fromDate, this.databaseName)
        .then(x => {
            this.items = x;
        })
    }
    
}

