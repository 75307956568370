
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins, Options, Vue } from "vue-class-component";
import WithModel from "@/mixins/withModel";

@Options({})
export default class OpSelect extends mixins(WithModel) {

    @Prop({
        default: []
    }) options: any[];

    isOpen: boolean = false;

    mounted(){
        document.addEventListener("click", this.closeSelect);
    }

    beforeUnmount(){
        document.removeEventListener("click", this.closeSelect);
    }

    closeSelect(ev){
        let parent = ev.target.parentElement
        while(parent && parent != this.$refs.root){
            parent = parent.parentElement
        }
        if(!parent)
            this.isOpen = false;
    }

    setOption(value){
        this.isOpen = false;
        this.emitModel(value);
    }

}
