import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/img/logo_mosaico.png'
import _imports_1 from '@/img/logo_dimagriamo.svg'
import _imports_2 from '@/img/logo_antismoking.png'
import _imports_3 from '@/img/logo_nutrilab.svg'
import _imports_4 from '@/img/logo_nutriamo.jpg'
import _imports_5 from '@/img/logo_nutrieasy.jpg'
import _imports_6 from '@/img/logo_nutrizionistiCheGuadagnano.jpg'


const _withScopeId = n => (_pushScopeId("data-v-b460b11c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1
}
const _hoisted_4 = {
  key: 2,
  src: _imports_2
}
const _hoisted_5 = {
  key: 3,
  src: _imports_3
}
const _hoisted_6 = {
  key: 4,
  src: _imports_4
}
const _hoisted_7 = {
  key: 5,
  src: _imports_5
}
const _hoisted_8 = {
  key: 6,
  src: _imports_6
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.url,
    target: "_blank"
  }, [
    (_ctx.resumeConfig.databaseName == 'Mosaico')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.resumeConfig.databaseName == 'Dimagriamo')
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.resumeConfig.databaseName == 'AntiSmoking')
      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.resumeConfig.databaseName == 'Nutrilab')
      ? (_openBlock(), _createElementBlock("img", _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.resumeConfig.databaseName == 'Nutriamo')
      ? (_openBlock(), _createElementBlock("img", _hoisted_6))
      : _createCommentVNode("", true),
    (_ctx.resumeConfig.databaseName == 'NutriEasy')
      ? (_openBlock(), _createElementBlock("img", _hoisted_7))
      : _createCommentVNode("", true),
    (_ctx.resumeConfig.databaseName == 'Nutrizionisti che guadagnano')
      ? (_openBlock(), _createElementBlock("img", _hoisted_8))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}