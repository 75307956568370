
import store from '@/store';
import { ModalServices } from '@/services/ModalServices'
import { StorageServices } from './services/StorageServices';
import { LocalizedClient, LoginClient } from './services/Services';
import { LoginServices } from './services/LoginServices';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class App extends Vue {

    get showSpinner(){
        return store.state.showSpinner > 0;
    }

    showApp: boolean = false;

    created(){
        ModalServices.setOpModal(this.$opModal);

        let proms: any[] = [
            LocalizedClient.getAll(),
            LocalizedClient.getEnabledLanguages(),
        ];
        Promise.all(proms)
        .then(xs => {
            store.state.consts.localizedValues = xs[0];
            store.state.consts.enabledLanguages = xs[1];
            if(!store.state.selectedLanguage){
                store.state.selectedLanguage = xs[1][0];
            }

            var loginData = StorageServices.getLoginData();
            if(loginData){
                LoginClient.refreshLogin()
                .then(x => {
                    if(loginData){
                        store.state.loginData = loginData;
                        LoginClient.refreshLogin()
                        .then(x => {
                            LoginServices.loginCallback(x)
                            .then(() => this.showApp = true);
                        })
                    }
                })
                .catch(() => this.showApp = true)
            } else {
                this.showApp = true;
            }
            
        })
    }
    
    setHightlights(event){
        event.preventDefault();
        (this.$refs.appContainer as HTMLElement).classList.add('highlightDrags');
    }
    unsetHighlights(event){
        event.preventDefault();
        (this.$refs.appContainer as HTMLElement).classList.remove('highlightDrags');
    }
}

