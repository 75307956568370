
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class companyLogo extends Vue {

    @Prop() resumeConfig: OM.ResumeConfig;

    get url(){
        if(this.resumeConfig.databaseName == 'Mosaico'){
            return "https://appmetodomosaico.com/";
        }
        if(this.resumeConfig.databaseName == 'Dimagriamo'){
            return "https://backoffice.dimagriamo.com/";
        }
        if(this.resumeConfig.databaseName == 'AntiSmoking'){
            return "https://app.antismoking-therapy.com/";
        }
        if(this.resumeConfig.databaseName == 'Nutrilab'){
            return "https://backoffice.nutrilab.app/"
        }
        if(this.resumeConfig.databaseName == 'NutriAmo'){
            return "https://backoffice.nutriamo.ch/"
        }
        if(this.resumeConfig.databaseName == 'NutriEasy'){
            return ""
        }
    }

}

