
import * as OM from '@/Model';
import { StorageServices } from '@/services/StorageServices';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class OpTableServerSide extends Vue {
    @Prop({
        default: () => []
    }) items: any[];
    @Prop({
        default: true
    }) showUpperPagination: boolean;
    @Prop({
        default: true
    }) showLowerPagination: boolean;
    @Prop({
        default: true
    }) showClearFilters: boolean;
    @Prop({
        default: true
    }) showItemsPerPageSelector: boolean;
    @Prop() opName: string;
    @Prop({
        type: Number,
        default: 10
    }) itemsPerPage: number;
    @Prop({
        default: () => {{}},
        required: false
    }) filter: any;
    @Prop({
        default: 0,
        required: true
    }) totalItemsCount: number;
    @Prop() clearFilters: number;

    tableRoot: any;

    sorts: SortDefinition[] = [];

    totalPages: number = 0;
    lowerPages: number = 0;
    upperPages: number = 0;
    pages: number[] = [];
    selectedPage: number = 1;
    xItemsPerPage: number = null;

    created(){
        this.xItemsPerPage = this.itemsPerPage;
    }

    setSelectedPage(index: number){
        this.selectedPage = index;
        this.calcPagination();
    }
    subtractPage(){
        if(this.selectedPage > 1){
            this.selectedPage--;
            this.calcPagination();
        }
    }
    addPage(){
        if(this.selectedPage < this.totalPages){
            this.selectedPage++;
            this.calcPagination();
        }
    }

    mounted(){
        var rootElement: any = this.$refs.rootElement;
        var classes = [];
        rootElement.classList.forEach(x => {
            classes.push(x);
        })
        rootElement.classList.value = "";
        this.tableRoot = this.$refs.tableRoot;
        classes.forEach(x => this.tableRoot.classList.add(x));
        
        let headTds = this.tableRoot.querySelectorAll('thead td')
        headTds.forEach(x => {
            this.insertFilter(x);
            this.insertSort(x);
        })

        let opFilters = StorageServices.getOpTableFilters(this.opName);
        if(opFilters){
            for(let p in opFilters){
                let filterInput = this.filterInputs[Object.keys(this.filterInputs).find(x => x == p)];
                if(filterInput){
                    filterInput.value = opFilters[p];
                    if(filterInput.value.toString() == 'undefined'){
                        filterInput.value = "";
                    }
                }
            }
        }
        
        this.calcPagination();
    }

    @Watch('xItemsPerPage')
    onItemsPerPageChange(next, prev){
        this.calcPagination();
    }

    @Watch("totalItemsCount")
    totalItemsChanged(){
        this.calcPagination();
    }

    @Watch("filter", { deep: true})
    onFilterChange(next, prev){
        this.$emit("search", this.filter)
    }

    calcPagination(){
        this.totalPages = Math.ceil((this.totalItemsCount) / this.xItemsPerPage);
        if(this.selectedPage > this.totalPages && this.selectedPage > 1){
            this.selectedPage = this.totalPages;
        }
        this.lowerPages = this.selectedPage - 2;
        this.upperPages = this.selectedPage + 2;
        
        if(this.selectedPage == 1)
            this.upperPages += 2;
        else if(this.selectedPage == 2)
            this.upperPages += 1;

        if(this.selectedPage == this.totalPages)
            this.lowerPages -= 2;
        else if(this.selectedPage == this.totalPages - 1)
            this.lowerPages -= 1;
        
        if(this.upperPages > this.totalPages)
            this.upperPages = this.totalPages;
        if(this.lowerPages <= 0)
            this.lowerPages = 1;
            
        this.pages = [];

        for(let i = this.lowerPages; i <= this.upperPages; i++){
            this.pages.push(i);
        }

        // this.filter = new OM.PagedRequest();
        this.filter.pageNumber = this.selectedPage;
        this.filter.perPage = this.xItemsPerPage;
    }

    insertSort(element){
        if(!element.hasAttribute('sort'))
            return;

        let prop = element.getAttribute('sort');
        let sortDefinition = new SortDefinition();
        sortDefinition.type = "";
        sortDefinition.el = element;
        element.onclick = () => {
            if(!sortDefinition.type){
                sortDefinition.type = 'asc';
                element.classList.add('asc');
            } else if(sortDefinition.type == 'asc') {
                sortDefinition.type = 'desc';
                element.classList.remove('asc');
                element.classList.add('desc');
            } else {
                sortDefinition.type = '';
                element.classList.remove('asc');
                element.classList.remove('desc');
            }
            this.applySorts(sortDefinition);
        }
        sortDefinition.fn = () => {
            this.filter.sortBy = new OM.SortByParameter();
            this.filter.sortBy.propertyName = prop;
            this.filter.sortBy.ascending = sortDefinition.type == 'asc';

            if(sortDefinition.type == "")
                this.filter.sortBy = null;
        }
        this.sorts.push(sortDefinition);
    }

    filterInputs: { [key: string]: HTMLInputElement } = {};
    insertFilter(element){
        if(!element.hasAttribute('filter'))
            return;

        var inputContainer = element.querySelector('.opTdFilterContainer');
        if(!inputContainer)
            inputContainer = document.createElement('div');
            
        var input = inputContainer.querySelector('input');
        if(!input)
            input = document.createElement('input');
        var input2 = inputContainer.querySelectorAll('input')[1];
        let propFrom = element.getAttribute('filterFrom');
        let propTo = element.getAttribute('filterTo');
        input.type = "text";
        let filterType = element.getAttribute('filterType');
        if(filterType){
            input.type = filterType;
            if(input.type == "checkbox"){
                input.value = "true";
            } else if(filterType == "dateRange"){
                input.type = "date";
            } else if(filterType == "numberRange"){
                input.type = "number";
            }
        }
        let prop = element.getAttribute('filter');
        
        input.onclick = (ev) => ev.stopPropagation();
        inputContainer.classList.add('opTdFilterContainer')
        input.classList.add('opTdFilter')
        element.append(inputContainer);
        
        if(input.type == "checkbox") {
            input.checked = !!this.filter[prop];
        } else if(filterType == "dateRange"){
            if(!input2)
                input2 = input.cloneNode();
            input.value = this.filter[propFrom];
            input2.value = this.filter[propTo];
        } else if(filterType == "numberRange"){
            if(!input2)
                input2 = input.cloneNode();
            input.value = this.filter[propFrom];
            input2.value = this.filter[propTo];
        } else {
            input.value = this.filter[prop];
        } 
        
        let filterFn = (item) => {
            let val = input.value.toString().toLowerCase().trim();
            if(input.type == "checkbox"){
                val = !!input.checked as any;
            }
            if(filterType == "dateRange"){
                let valFrom = input.value.toString().toLowerCase().trim();
                let valTo = input2.value.toString().toLowerCase().trim();
                this.filter[propFrom] = valFrom;
                this.filter[propTo] = valTo;
            } else if(filterType == "numberRange"){
                let valFrom = input.value.toString().toLowerCase().trim();
                let valTo = input2.value.toString().toLowerCase().trim();
                this.filter[propFrom] = valFrom;
                this.filter[propTo] = valTo;
            } else {
                this.filter[prop] = val;
            }
        }
        input.oninput = filterFn;
        if(input2){
            input2.oninput = filterFn;
            input2.onclick = (ev) => ev.stopPropagation();
        }

        inputContainer.append(input);
        if(input2){
            inputContainer.append(input2);
        }

        if(input2){
            this.filterInputs[propFrom] = input;
            this.filterInputs[propTo] = input2;
        } else {
            this.filterInputs[prop] = input;
        }

    }
    
    @Watch('clearFilters')
    onClearFilters(){
        for(let p in this.filterInputs){
            this.filterInputs[p].value = "";
            this.filter[p] = this.filterInputs[p].value;
        }
        this.$emit('filtersCleared');
    }

    getValueByProp(input: any, propString: string){
        let props = propString.split('.');
        let ris = input;
        props.forEach(x => {
            if(ris != null)
                ris = ris[x];
        })
        return ris;
    }

    applyFilters(){
        // this.calcPagination();
    }

    applySorts(def: SortDefinition){
        this.sorts.forEach(x => {
            if(x.el != def.el){
                x.el.classList.remove('asc');
                x.el.classList.remove('desc');
                x.type = '';
                return;
            }
        })
        def.fn();
    }

}

class SortDefinition {
    type: string;
    el: HTMLElement;
    fn: () => void;
}

