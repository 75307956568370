import { vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["rows", "cols", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    style: _normalizeStyle(_ctx.styles),
    ref: "textElement",
    rows: _ctx.rows,
    cols: _ctx.cols,
    placeholder: _ctx.placeholder,
    wrap: "hard",
    onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resize && _ctx.resize(...args))),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event))
  }, null, 44, _hoisted_1)), [
    [_vModelText, _ctx.localValue]
  ])
}