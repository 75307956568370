import { CommonServices } from "@/services/common";
import { baseUrl } from '@/config';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    setOpTableFilters(key: string, filters: any){
        window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
    }
    getOpTableFilters(key: string){
        return this.readFromLocalStorage<any>('_op_' + key);
    }
    
    setLoginData(data: OM.LoginData){
        window.localStorage.setItem('_loginData', JSON.stringify(data));
    }
    getLoginData(){
        return this.readFromLocalStorage<OM.LoginData>('_loginData');
    }

    SetLanguage(language: string) {
        window.localStorage.setItem('language', language);
    }
    GetLanguage() {
        let data = window.localStorage.getItem('language');
        if(!data){
            return '';
        } else {
            return data;
        }
    }

}

export let StorageServices = new _StorageServices();