import * as OM from './Model';
import * as VM from './viewModel';
import { createRouter, createWebHashHistory, RouteComponent, RouteRecordRaw } from 'vue-router';
import Login from './views/Login.vue';

let _AppModules: VM.AppRoute[] = [];

_AppModules.push(new VM.AppRoute(VM.BackendModules.Dashboard, 'Dashboard', '/dashboard', 'fa fa-dashboard', RoutesGroup.Performance));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Utenze, 'Utenze', '', 'fa fa-user-secret', RoutesGroup.Configurazione, [
    new VM.AppRoute(0, 'Lista', '/utenze/list', '', '')
]));
_AppModules.push(new VM.AppRoute(VM.BackendModules.AppConfig, 'Configurazione generale', '/appConfig', 'fa fa-cog', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Dictionary, 'Dizionario testi', '/dictionary', 'fa fa-language', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.ResumeConfig, 'Configurazione dashboard', '/resumeConfig/list', 'fa fa-cog', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.SpesaProject, 'Spese progetto', '/spesaProject/list', 'fa fa-list', RoutesGroup.Performance));


export const AppModules = _AppModules.sort((a, b) => a.label > b.label ? 1 : -1);

import layoutRoutes from '@/views/layout/routes';
import store from './store';
import { RoutesGroup } from './enum';

let _routes: RouteRecordRaw[] = [
    { path: '/', component: Login },
];
_routes = _routes.concat(layoutRoutes);

let _router = createRouter({
    history: createWebHashHistory(),
	routes: _routes,
    scrollBehavior: (to, from, savedPosition) => {
        window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
    }
});

_router.beforeEach((to, from, next) => {
    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
})

_router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
})

export default _router;