
import WithModel from '@/mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Switcher extends mixins(WithModel) {

    @Prop() label: string;

    emit(val){
        this.emitModel(val);
    }
}

