import { RouteComponent, RouteRecordRaw } from 'vue-router';
import Layout from './Layout.vue';
import utenze from '@/views/utenze/routes';
import dashboardUtente from '@/views/dashboard/routes';
import mailSentLog from'@/views/mailSentLog/routes';
import appConfig from'@/views/appConfig/routes';
import dictionary from '../dictionary';
import resumeConfig from '../resumeConfig';
import spesaProject from '../spesaProject';

let children: RouteRecordRaw[] = [];
let layoutRoutes: RouteRecordRaw[] = [
    {
        path: '/',
        component: Layout,
        children: children
            .concat(utenze)
            .concat(dashboardUtente)
            .concat(appConfig)
            .concat(dictionary.routes())
            .concat(resumeConfig.routes)
            .concat(spesaProject.routes)
    }
];

export default layoutRoutes;