import 'bootstrap/dist/css/bootstrap.css';
import './css/font-awesome.css';
import './css/animate.css';
import './css/toastr.min.css';
import './css/style.css';
import './filters';

import router from './router'
import store from './store'
import modalPlugin from './plugins/modalPlugin';
import DIRECTIVES from '@/directives';
import FILTERS from '@/filters';
import toastr from 'toastr';
import moment from 'moment';

moment.locale("it");
toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 4000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};
window.toastr = toastr;

router.beforeEach((to, from, next) => {
    store.state.layoutSlugs = [];
    store.state.layoutSlugButtonAdd = null;
    next();
});

import '@/directives';
import App from '@/App.vue';
import { CommonServices } from '@/services/common'
import { createApp } from 'vue';
import { LocalizationServices } from './services/LocalizationServices';

CommonServices.showSpinnerCb = () => {
    store.state.showSpinner++;
}
CommonServices.hideSpinnerCb = () => {
    store.state.showSpinner--;
    if(store.state.showSpinner < 0){
        store.state.showSpinner = 0;
    }
}
CommonServices.unauthorizedCb = () => {
    if(router.currentRoute.value.path != '/')
        router.push('/?redirect=' + router.currentRoute.value.fullPath);
}

router.afterEach((to, from) => {
    store.state.pageTitle = <string>to.meta.title;
});

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin);

for(let prop in DIRECTIVES){
    app.directive(prop, DIRECTIVES[prop])
}

app.config.globalProperties.$filters = FILTERS;
app.config.globalProperties.$localizationService = LocalizationServices;


const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})
const dashboardContext = require.context('./views/dashboard', true, /\.vue$/i);
dashboardContext.keys().forEach(fileName => {
    var component = dashboardContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})



app.mount('#app');