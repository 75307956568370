import moment, { months } from 'moment';
import * as OM from '@/Model';
import store from './store';

export function JsonClone(item){
    return JSON.parse(JSON.stringify(item));
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized){}
        localized = new OM.LocalizedValue();

    if(!localized.values)
        localized.values = {};
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
}

export function guid() { 
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) { 
        const r = Math.random() * 16 | 0,  
            v = c == 'x' ? r : (r & 0x3 | 0x8); 
        return v.toString(16); 
    }); 
}

export function groupBy(collection, property) {
    var i = 0, val, index,
        values = [], result = {};
    for (; i < collection.length; i++) {
        val = getValueByProp(collection[i], property);
        index = values.indexOf(val);
        if (index > -1)
            result[val].push(collection[i]);
        else {
            values.push(val);
            result[val] = [];
            result[val].push(collection[i]);
        }
    }
    return result;
}
function getValueByProp(input: any, propString: string){
    let props = propString.split('.');
    let ris = input;
    props.forEach(x => {
        if(ris != null)
            ris = ris[x];
    })
    return ris;
}

export function getInizioTrimestre(mese: number){
    mese += 1;
    let currentYear = new Date().getFullYear().toString();
    if(mese >= 1 && mese <= 3){
        return moment(`${currentYear}-01-1`);
    } else if(mese >= 4 && mese <= 6) {
        return moment(`${currentYear}-04-1`);
    } else if(mese >= 7 && mese <= 9) {
        return moment(`${currentYear}-07-1`);
    } else if(mese >= 10 && mese <= 12)  {
        return moment(`${currentYear}-10-1`);
    }
}

export function getFineTrimestre(mese: number){
    mese += 1;
    let currentYear = new Date().getFullYear().toString();
    if(mese >= 1 && mese <= 3){
        return moment(`${currentYear}-01-01`).add(3, 'months').subtract(1,'second');
    } else if(mese >= 4 && mese <= 6) {
        return moment(`${currentYear}-04-01`).add(3, 'months').subtract(1,'second');
    } else if(mese >= 7 && mese <= 9) {
        return moment(`${currentYear}-07-01`).add(3, 'months').subtract(1,'second');
    } else if(mese >= 10 && mese <= 12)  {
        return moment(`${currentYear}-10-01`).add(3, 'months').subtract(1,'second');
    }
}

export function validateEmail(input){
    return String(input)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};