
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';
import { DashboardClient, ResumeConfigClient } from '@/services/Services';
import moment from 'moment';
import companyLogo from './companyLogo.vue';
import speseOfMonthModal from './speseOfMonthModal.vue';

@Options({
    components: {
        companyLogo
    }
})
export default class companyResume extends Vue {

    @Prop() resumeNameId: OM.NameIdentifier;

    resumeConfig: OM.ResumeConfig = null;
    resume: OM.DashboardResumeVm = null;

    loading: boolean = false;
    currentDate: string = "";
    
    created(){
        this.currentDate = moment().toISOString();
        ResumeConfigClient.getById(this.resumeNameId.identifier)
        .then(x => {
            this.resumeConfig = x;
            this.init();
        })
    }

    init(){
        this.loading = true;
        let fromDate = moment(this.currentDate).startOf('month').toISOString();
        let toDate = moment(this.currentDate).endOf('month').toISOString();
        DashboardClient.getResumeByConfig(this.resumeConfig.identifier, fromDate, toDate, false)
        .then(x => {
            this.resume = x;
        })
        .finally(() => {
            this.loading = false;
        })
    }

    openSpeseModal(){
        let fromDate = moment(this.currentDate).startOf('month').toISOString();
        let toDate = moment(this.currentDate).endOf('month').toISOString();
        this.$opModal.show(speseOfMonthModal, {
            fromDate: fromDate,
            databaseName: this.resumeConfig.databaseName
        })
    }

}

