import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8da8dd22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = {
  filter: "mese",
  sort: "mese"
}
const _hoisted_3 = {
  filter: "anno",
  sort: "anno"
}
const _hoisted_4 = {
  filter: "descrizione",
  sort: "descrizione"
}
const _hoisted_5 = {
  filter: "importo",
  sort: "importo"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { colspan: "99" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_op_table, {
      class: "table table-striped",
      items: _ctx.items
    }, {
      default: _withCtx((opData) => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_2, [
              _createVNode(_component_localized_text, {
                localizedKey: "Mese",
                text: "Mese"
              })
            ]),
            _createElementVNode("td", _hoisted_3, [
              _createVNode(_component_localized_text, {
                localizedKey: "Anno",
                text: "Anno"
              })
            ]),
            _createElementVNode("td", _hoisted_4, [
              _createVNode(_component_localized_text, {
                localizedKey: "Descrizione",
                text: "Descrizione"
              })
            ]),
            _createElementVNode("td", _hoisted_5, [
              _createVNode(_component_localized_text, {
                localizedKey: "Importo",
                text: "Importo"
              })
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          (opData.items.length == 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                _createElementVNode("td", _hoisted_7, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessun elemento trovato",
                    text: "Nessun elemento trovato"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((opData.items as OM.SpesaProject[]), (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(item.mese), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(item.anno), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(item.descrizione), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$filters.currency(item.importo)) + " €", 1)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ]))
}