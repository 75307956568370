import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "control-label" }
const _hoisted_3 = { class: "btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn btn-link", _ctx.localValue ? 'btn-green' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit(true)))
      }, "Si", 2),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn btn-link", !_ctx.localValue ? 'btn-red' : '']),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit(false)))
      }, "No", 2)
    ])
  ]))
}