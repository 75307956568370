import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { LoginClient } from './services/Services';
import { StorageServices } from './services/StorageServices';
import { createStore, StoreOptions } from 'vuex';

let state = {
    showSpinner: 0,
    loginData: <OM.LoginData>null,
    user: <OM.BaseUser>null,
    selectedLanguage: "",
    consts: {
        localizedValues: [],
        enabledLanguages: [],
    },
    modules: <any[]>[],
    pageTitle: "",
    layoutSlugs: <VM.LayoutSlug[]>[],
    layoutSlugButtonAdd: <VM.ButtonSettings>null,
    descendants: <OM.NameIdentifier[]>[],
    beforeNavCbs: [],
    afterNavCbs: [],
    copyToClipboard: copyToClipboard,
    isLead: (role?: string) => {
        let checkWith = role ? role : state.user && state.user.role;
        return checkWith == Enum.UserRoles.Lead;
    },
    isCliente: (role?: string) => {
        let checkWith = role ? role : state.user && state.user.role;
        return checkWith == Enum.UserRoles.Cliente;
    },
    isCollaboratore: (role?: string) => {
        let checkWith = role ? role : state.user && state.user.role;
        return checkWith == Enum.UserRoles.Collaboratore;
    },
    isCentro: (role?: string) => {
        let checkWith = role ? role : state.user && state.user.role;
        return checkWith == Enum.UserRoles.CentroFumo;
    },
    isDottore: (role?: string) => {
        let checkWith = role ? role : state.user && state.user.role;
        return checkWith == Enum.UserRoles.Dottore;
    },
    isManager: (role?: string) => {
        let checkWith = role ? role : state.user && state.user.role;
        return checkWith == Enum.UserRoles.Manager; 
    },
    isAdmin: (role?: string) => {
        let checkWith = role ? role : state.user && state.user.role;
        return checkWith == Enum.UserRoles.Admin;
    },
}
let vuestore: StoreOptions<typeof state> = {
    state: state,
	mutations: {
		showSpinner(state){
            state.showSpinner++;
		},
		hideSpinner(state){
            state.showSpinner--;
        }
	},
}


let store = createStore(vuestore);
export default store


function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    window.toastr.success("Copiato!");
}