
import WithModel from "@/mixins/withModel";
import { mixins, Options } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";

function delegate(el, evt, sel, handler) {
    el.addEventListener(evt, function(event) {
        var t = event.target;
        while (t && t !== this) {
            if (t.matches(sel)) {
                handler.call(t, event);
            }
            t = t.parentNode;
        }
    });
}

@Options({})
export default class vAutocomplete extends mixins(WithModel) {

    @Prop({
        type: [Object, Array]
    }) list: any;
    @Prop({
        type: [Object, Function]
    }) searchCallback: any;
    @Prop({
        type: null
    }) value: any;
    @Prop() objKey: string;
    
    results = [];
    autoCompleteListHeight = 0;

    get local(){
        return this.value || '';
    }

    @Watch('value')
    onValueChange(next, prev){
        if(next){
            if(this.objKey)
                this.search.value = next[this.objKey];
            else
                this.search.value = next;
        } else {
            this.search.value = '';
        }
    }

    setResult(item){
        this.emitModel(item);
        if(this.objKey)
            this.search.value = item[this.objKey];
        else
            this.search.value = item;
    }

    search: any = { value: '' };
    selectedIndex: number = 0;

    @Watch('search.value')
    onSearchChange(next, prev){
        this.selectedIndex = 0;
        if(this.searchCallback){
            this.results = this.searchCallback(next);
        }
        
        this.$nextTick( () => {
            //this.autocompleteList: HTMLElement = <HTMLElement>this.$refs.autocompleteList;
            this.autocompleteList.scrollTo(0, 0);
            this.autoCompleteListHeight = this.autocompleteList.offsetHeight;
        })
    }

    container: HTMLElement = null;
    autocompleteList: HTMLElement = null;
    mounted(){
        this.container = this.$refs.autocompleteContainer as HTMLElement;
        this.autocompleteList = this.$refs.autocompleteList as HTMLElement;
        delegate(this.container, 'keydown', 'input', (ev) => {
            let listItem = this.autocompleteList.querySelector('._v-autocomplete-list-item');
            if(!listItem)
                return;
            let itemHeight = parseInt(getComputedStyle(listItem).height);
            let maxItems = Math.floor(this.autoCompleteListHeight / itemHeight);

            if(!ev.key)
                return;
            if(ev.key == "ArrowUp"){
                if(this.selectedIndex > 0)
                    this.selectedIndex--;
                this.autocompleteList.scrollTop -= itemHeight;
            } else if(ev.key == "ArrowDown") {
                if(this.selectedIndex < this.results.length - 1)
                    this.selectedIndex++;

                if((this.selectedIndex + 1) / maxItems > 1){
                    this.autocompleteList.scrollTop += itemHeight;
                }
            } else if(ev.key == "Enter") {
                this.setResult(this.results[this.selectedIndex]);
            }
        })
    }


}
