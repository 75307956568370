
import WithModel from '@/mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class FileSelect extends mixins(WithModel) {

    onChange(ev: any){
        if (ev) {
            let file = ev.target.files[0];
            this.emitModel(file);
        }
    }
}
