import { ModalInput } from './classes'
class _ModalBus {

    addModalCb: any;
    registerAddModalCallback(cb){
        this.addModalCb = cb;
    }
    addModal(item, props, closeCallback){
        this.addModalCb(item, props, closeCallback);
    }
    
    registerCloseLastModal(cb){
        this.closeLastModal = cb;
    }
    closeLastModal(){}

    registerCloseAllModals(cb){
        this.closeAllModals = cb;
    }
    closeAllModals(){}

    modals: ModalInput[] = [];
    setModalsArray(arr: ModalInput[]){
        this.modals = arr;
    }
    getModals = () => {
        return this.modals;
    }

}

export const ModalBus = new _ModalBus();