import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/resumeConfig/list',
        component: () => import("./resumeConfigList.vue"),
        meta: { title: "Lista configurazione dashboard"},
    },
    {
        path: '/resumeConfig/detail/:identifier',
        component: () => import("./resumeConfigDetail.vue"),
        meta: { title: "Configurazione dashboard"},
    }
];

export default routes;