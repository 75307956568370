
import WithModel from "@/mixins/withModel";
import { mixins, Options } from "vue-class-component";

@Options({})
export default class FileDropper extends mixins(WithModel) {

    click(){
        (this.$refs.fileInput as any).click();
    }

    dropImage(ev){
        ev.stopPropagation();
        ev.preventDefault();
        let file = ev.dataTransfer.files[0];
        this.emitModel(file);
    }

    onFileSelect(ev){
        let file = ev.target.files[0];
        this.emitModel(file);
    }
}

