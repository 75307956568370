import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d57c8ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-between align-items-center w-100" }
const _hoisted_2 = { class: "obiettivoLineContainer" }
const _hoisted_3 = { class: "obiettivoLine target" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { class: "left" }
const _hoisted_6 = { class: "fs-36 font-weight-bold ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_localized_text, {
            localizedKey: "Target",
            text: "Target"
          })
        ]),
        _createElementVNode("div", {
          class: "right",
          style: _normalizeStyle(_ctx.targetWidth)
        }, "€ " + _toDisplayString(_ctx.$filters.currency(_ctx.obiettivo)), 5)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["obiettivoLine result", { red: !_ctx.isOver, green: _ctx.isOver }])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_localized_text, {
            localizedKey: "Risultato",
            text: "Risultato"
          })
        ]),
        _createElementVNode("div", {
          class: "right",
          style: _normalizeStyle(_ctx.resultWidth)
        }, "€ " + _toDisplayString(_ctx.$filters.currency(_ctx.risultato)), 5)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.risultatoPerc.toFixed(0)) + "% ", 1)
  ]))
}