import { vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_date = _resolveDirective("date")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "datetime-local",
    class: "form-control",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
    onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitIsoDate && _ctx.emitIsoDate(...args)))
  }, null, 544)), [
    [_vModelText, _ctx.localValue],
    [_directive_date, _ctx.localValue]
  ])
}