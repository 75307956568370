
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { BaseUserClient, ResumeConfigClient } from '@/services/Services';

@Options({})
export default class dashboardFund extends Vue {

    @Prop() user: OM.BaseUser;

    resumeConfigs: OM.NameIdentifier[] = [];

    created(){
        BaseUserClient.getResumeConfigsByUser(this.user.identifier)
        .then(x => {
            this.resumeConfigs = x;
        })
    }
    
}

