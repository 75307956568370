
import WithModel from "@/mixins/withModel";
import { mixins, Options } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";

@Options({})
export default class autoTextarea extends mixins(WithModel) {

    @Prop() placeholder;
    @Prop({
        default: 2
    }) rows: number;
    @Prop({
        default: 0
    }) cols: number;
    @Prop({
        default: 50
    }) minHeight: number;
    @Prop() maxHeight: number;
    @Prop({
        default: true
    }) autoResize: boolean;

    height: string = "";
    isScrollEnabled: boolean = false;

    get styles() {
        return {
            resize: this.autoResize ? "none !important" : "",
            padding: `5${this.unit}`,
            height: this.height,
            overflow: `${this.isScrollEnabled ? "scroll" : "hidden"} !important`,
        } as any;
    }
    get unit() {
        return "px";
    }
    @Watch('localValue')
    onLocalValueChange(next, prev) {
        this.emitModel(this.localValue);
        this.resize();
    }

    resize() {
        const element: any = this.$refs.textElement;
        if(!element)
            return;
        this.height = "auto !important";
        this.$nextTick(() => {
            if (this.minHeight) {
                this.height = `${element.scrollHeight < this.minHeight ? this.minHeight : element.scrollHeight}${this.unit}`;
            }
            if (this.maxHeight) {
                if (element.scrollHeight > this.maxHeight) {
                    this.height = `${this.maxHeight}${this.unit}`;
                    this.isScrollEnabled = true;
                } else {
                    this.isScrollEnabled = false;
                }
            }
        })
    }

    created() {
        this.$nextTick(() => {
            this.localValue = this.localValue;
        })
    }
    mounted() {
        this.resize();
    }
};
