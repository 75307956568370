

import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import WithModel from '@/mixins/withModel';
import moduleSelector from './moduleSelector.vue';

@Options({
    components: {
        moduleSelector
    }
})
export default class roleModules extends mixins(WithModel) {

    declare localValue: OM.RoleModules[];
    roles: string[] = Enum.UserRoles.GetAll();
    tempRole: string = "";

    get availableRoles(){
        return this.roles.filter(x => !this.localValue.some(c => c.role == x));
    }

    addRole(){
        this.localValue.push(new OM.RoleModules());
    }

    removeRole(i: number){
        this.localValue.splice(i, 1);
    }

    selectRole(item: OM.RoleModules){
        item.role = this.tempRole;
        this.tempRole = "";
    }

}

