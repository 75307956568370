
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import * as VM from '@/viewModel'
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel'
import { AppModules } from '@/router';

@Options({})
export default class moduleSelector extends mixins(WithModel) {

    modules: VM.AppRoute[] = AppModules;

    declare localValue: number[];

    search: string = "";

    selectAll(){
        this.localValue = [];
        this.modules.forEach(x => {
            this.localValue.push(x.id)
        })
        this.emitModel(this.localValue);
    }

    @Watch('localValue')
    onLocalValueChange(next, prev){
        this.emitModel(this.localValue);
    }


    get filteredModules(){
        return this.modules.filter(x => x.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            x.id.toString() == this.search);
    }

}
