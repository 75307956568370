
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';

@Options({})
export default class datiBaseUser extends Vue {
 
    @Prop() user: OM.BaseUser;
    @Prop({
        default: null
    }) roles: string[];
    @Prop() readonly: boolean;

    rolesList: string[] = Enum.UserRoles.GetAll();

}

