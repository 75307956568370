import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "form-group"
}
const _hoisted_3 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Username (email per la login)",
          text: "Username (email per la login)"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.username) = $event))
      }, null, 512), [
        [_vModelText, _ctx.value.username]
      ])
    ]),
    _createElementVNode("label", {
      class: "text-warning pointer",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.doChangePassword && _ctx.doChangePassword(...args)))
    }, [
      (!_ctx.changePassword)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            localizedKey: "Clicca per modificare la password",
            text: "Clicca per modificare la password"
          }))
        : (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            localizedKey: "Annulla",
            text: "Annulla"
          }))
    ]),
    (_ctx.changePassword)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Nuova password",
              text: "Nuova password"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPassword) = $event))
          }, null, 512), [
            [_vModelText, _ctx.newPassword]
          ]),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-success btn-sm",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.setNewPassword && _ctx.setNewPassword(...args)))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Salva nuova password",
              text: "Salva nuova password"
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Login abilitata",
          text: "Login abilitata"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.loginEnabled) = $event)),
        class: "form-control"
      }, null, 512), [
        [_vModelCheckbox, _ctx.value.loginEnabled]
      ])
    ])
  ]))
}