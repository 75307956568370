import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Nome",
          text: "Nome"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.name) = $event))
      }, null, 512), [
        [_vModelText, _ctx.value.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Cognome",
          text: "Cognome"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.surname) = $event))
      }, null, 512), [
        [_vModelText, _ctx.value.surname]
      ])
    ])
  ]))
}